




















































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NewsletterWidget extends Vue {
  @Prop({ type: String, default: 'Newsletter' }) readonly title!: string
  @Prop({ type: Array, default: () => ['MORNING'] }) readonly lists!: []
  @Prop({ type: String, default: '' }) readonly theme!: string

  private submitted: boolean = false
  private response: any = null
  private email: string = ''

  async subscribe(): Promise<void> {
    this.submitted = true
    this.response = null
    const mutation = require('~base/graphql/mutations/SubscribeNewsletter.gql')
    const apolloClient = this.$apolloProvider.defaultClient
    this.response = await apolloClient.mutate({
      mutation,
      variables: {
        email: this.email,
        lists: this.lists,
      },
    })
  }

  get status() {
    if (!this.response) {
      return null
    }

    return this.response.data.subscribeNewsletter.success
      ? 'Anmeldung erfolgreich'
      : 'Anmeldung fehlgeschlagen'
  }
}
